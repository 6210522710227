import styled from "styled-components";

export const Container = styled("div")(({ theme }) => {
  const {
    customPalette: { darkGrey },
  } = theme;

  return {
    height: "56px",
    bottom: "56px",
    left: "440px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: `1px solid ${darkGrey}`,
    backgroundColor: darkGrey,
    position: "relative",
    borderRadius: "8px",
    "&::before": {
      content: "''",
      position: "absolute",
      top: "40%",
      left: "-10px",
      transform: "translateY(40%)",
      borderTop: "8px solid transparent",
      borderBottom: "8px solid transparent",
      borderRight: `8px solid ${darkGrey}`,
    },
  };
});

export const ContentContainer = styled("div")(({ theme }) => {
  return {
    padding: "16px",
  };
});

export const Text = styled("p")(({ theme }) => {
  const {
    customPalette: { white },
    customSizes: {
      textField: {
        text: { fontSize, fontWeight, lineHeight },
      },
    },
  } = theme;

  return {
    fontSize,
    fontWeight,
    lineHeight,
    color: white,
  };
});
