import React, { PropsWithChildren, useState, useEffect } from "react";

import {
  Container,
  InfoContainer,
  Subtitle,
  SubtitleContainer,
  SwitchContainer,
  SwitchStyled,
  TextContainer,
  Title,
} from "./styles";
import { InfoIcon } from "assets";
import InfoModal from "components/infoModal";

interface SettingsDetailsProps extends PropsWithChildren {
  title: string;
  subtitle: string;
  checked?: boolean;
  switchHandler?: () => void;
  infoButton?: boolean;
}

const SettingsDetails = ({
  title,
  subtitle,
  checked,
  switchHandler,
  infoButton,
}: SettingsDetailsProps) => {
  const [showInfo, setShowInfo] = useState(false);

  const handleClick = (event) => {
    event.stopPropagation();
    setShowInfo(!showInfo);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      const container = document.getElementById("container");
      if (container && !container.contains(event.target)) setShowInfo(false);
    };

    document.addEventListener("click", handleOutsideClick);

    return () => document.removeEventListener("click", handleOutsideClick);
  }, [showInfo]);

  return (
    <Container id="container">
      <TextContainer>
        <Title>{title}</Title>
        <SubtitleContainer>
          <Subtitle>{subtitle}</Subtitle>
          {infoButton && (
            <InfoContainer onClick={handleClick}>
              <InfoIcon />
            </InfoContainer>
          )}
        </SubtitleContainer>
        {showInfo && <InfoModal />}
      </TextContainer>
      {switchHandler && <SwitchContainer>
        <SwitchStyled
          focusVisibleClassName=".Mui-focusVisible"
          disableRipple
          checked={checked}
          onChange={switchHandler}
        />
      </SwitchContainer>}
    </Container>
  );
};

export default SettingsDetails;
