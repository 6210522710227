import styled from "styled-components";

import theme from "../../definitions/theme";
import { ButtonProps } from ".";

const getBackgroundColor = (
  type: "primary" | "secondary",
  disabled?: boolean
) => {
  return theme.customPalette.button[type][disabled ? "disable" : "main"];
};

const getShadow = (type: "primary" | "secondary") =>
  theme.customPalette.button.shadow[type];

const getBorder = (type: "primary" | "secondary") => {
  return theme.customPalette.button.border[type];
};

const getColor = (type: "primary" | "secondary") => {
  return theme.customPalette.button.text[type];
};

const getHoverBackgroundColor = (
  type: "primary" | "secondary",
  disabled?: boolean
) => {
  return theme.customPalette.button[type][disabled ? "disable" : "hover"];
};

const getActiveBackgroundColor = (
  type: "primary" | "secondary",
  disabled?: boolean
) => {
  return theme.customPalette.button[type][disabled ? "disable" : "click"];
};

const getFontSize = (type: "primary" | "secondary") => {
  return theme.customSizes.button[type].fontSize;
};

const getFontWeight = (type: "primary" | "secondary") => {
  return theme.customSizes.button[type].fontWeight;
};

const getLineHeight = (type: "primary" | "secondary") => {
  return theme.customSizes.button[type].lineHeight;
};

const getOpacity = (type: "primary" | "secondary", disabled?: boolean) => {
  if (type === "secondary" && disabled) return "0.5";
  return "1";
};

export const StyledButton = styled("button")<ButtonProps>`
  background-color: ${({ variantType, disabled, color }) =>
    color || getBackgroundColor(variantType, disabled)};
  opacity: ${({ variantType, disabled }) => getOpacity(variantType, disabled)};
  border: ${({ variantType }) => getBorder(variantType)};
  border-radius: ${({ borderRadius }) => borderRadius ?? 15}px;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  color: ${({ textColor, variantType }) => textColor || getColor(variantType)};
  cursor: ${({ disabled }) => (disabled ? "initial" : "pointer")};
  font-size: ${({ variantType, fontSize }) =>
    fontSize ? `${fontSize}px` : getFontSize(variantType)};
  font-weight: ${({ variantType, fontWeight }) =>
    fontWeight ? `${fontWeight}px` : getFontWeight(variantType)};
  font-family: ${({ fontFamily }) => fontFamily || theme.customFonts.poppins};
  height: ${({ height }) => (height ? `${height}px` : "auto")};
  line-height: ${({ variantType, lineHeight }) =>
    lineHeight ? `${lineHeight}px` : getLineHeight(variantType)};
  bottom: ${({ bottomButton }) => bottomButton};
  margin: 0;
  padding: ${({ padding }) => padding};
  position: ${({ position }) => position};
  width: ${({ width }) =>
    width ? (typeof width === "number" ? `${width}px` : width) : "auto"};
  min-width: ${({ minWidth }) => (minWidth ? `${minWidth}px` : "unset")};
  :hover {
    background-color: ${({ variantType, disabled, color }) =>
      color || getHoverBackgroundColor(variantType, disabled)};
    box-shadow: ${({ variantType, disabled, color }) =>
      !disabled &&
      (color ? "0 4px 14px rgba(0, 0, 0, 0.2)" : getShadow(variantType))};
  }
  :active {
    background-color: ${({ variantType, disabled, color }) =>
      color || getActiveBackgroundColor(variantType, disabled)};
  }
`;
