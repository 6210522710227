import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { QuestionGroupState, SurveysState, SurveyState } from "../../types";

const initialState: SurveysState = {
  surveys: [],
  currentDefaultId: null,
  apiError: false,
};

const surveySlice = createSlice({
  name: "survey",
  initialState,
  reducers: {
    setSurveys: (state, action: PayloadAction<SurveyState[]>) => {
      state.surveys = [
        ...action.payload.map((survey) => ({
          id: survey.id,
          name: survey.name,
          isDefault: survey.isDefault,
          questionGroups: survey.questionGroups,
          created_at: survey.created_at,
          updated_at: survey.updated_at,
        })),
      ];

      state.currentDefaultId =
        action.payload.find(({ isDefault }) => isDefault)?.id || null;
    },
    addSurvey: (state, action: PayloadAction<SurveyState>) => {
      state.surveys.push(action.payload);
    },
    updateSurvey: (
      state,
      action: PayloadAction<{
        surveyId: number;
        name: string;
        isDefault: boolean;
      }>
    ) => {
      let surveyIndex = state.surveys.findIndex(
        ({ id }) => id === action.payload.surveyId
      );

      if (surveyIndex !== -1) {
        state.surveys[surveyIndex].name = action.payload.name;
        state.surveys[surveyIndex].isDefault = action.payload.isDefault;
        state.currentDefaultId = state.surveys.find(
          ({ isDefault }) => isDefault
        ).id;
      }
    },
    updateQuestionGroup: (
      state,
      action: PayloadAction<{
        surveyId: number;
        questionGroup: QuestionGroupState;
      }>
    ) => {
      let surveyIndex = state.surveys.findIndex(
        ({ id }) => id === action.payload.surveyId
      );

      if (surveyIndex !== -1) {
        let index = state.surveys[surveyIndex].questionGroups.findIndex(
          ({ id }) => id === action.payload.questionGroup.id
        );
        state.surveys[surveyIndex].questionGroups[index] =
          action.payload.questionGroup;
      }
    },
    updateSurveyQuestionGroups: (
      state,
      action: PayloadAction<{ surveyId: number; questionGroupId: number }>
    ) => {
      let surveyIndex = state.surveys.findIndex(
        ({ id }) => id === action.payload.surveyId
      );

      if (surveyIndex !== -1) {
        const updatedGroups = state.surveys[surveyIndex].questionGroups.filter(
          (group) => group.id !== action.payload.questionGroupId
        );

        state.surveys[surveyIndex].questionGroups = updatedGroups;
      }
    },
    addQuestionGroup: (
      state,
      action: PayloadAction<{
        surveyId: number;
        questionGroup: QuestionGroupState;
      }>
    ) => {
      let surveyIndex = state.surveys.findIndex(
        ({ id }) => id === action.payload.surveyId
      );

      if (surveyIndex !== -1) {
        state.surveys[surveyIndex].questionGroups.push(
          action.payload.questionGroup
        );
      }
    },
    setQuestionDeleted: (
      state,
      action: PayloadAction<{ surveyId: number; deleted: boolean }>
    ) => {
      let surveyIndex = state.surveys.findIndex(
        ({ id }) => id === action.payload.surveyId
      );

      if (surveyIndex !== -1) {
        state.surveys[surveyIndex].questionDeleted = action.payload.deleted;
      }
    },
    setQuestionCreated: (
      state,
      action: PayloadAction<{ surveyId: number; created: boolean }>
    ) => {
      let surveyIndex = state.surveys.findIndex(
        ({ id }) => id === action.payload.surveyId
      );

      if (surveyIndex !== -1) {
        state.surveys[surveyIndex].questionCreated = action.payload.created;
      }
    },
    setQuestionGroupCreated: (
      state,
      action: PayloadAction<{ surveyId: number; created: boolean }>
    ) => {
      let surveyIndex = state.surveys.findIndex(
        ({ id }) => id === action.payload.surveyId
      );

      if (surveyIndex !== -1) {
        state.surveys[surveyIndex].questionGroupCreated =
          action.payload.created;
      }
    },
    setQuestionGroupDeleted: (
      state,
      action: PayloadAction<{ surveyId: number; deleted: boolean }>
    ) => {
      let surveyIndex = state.surveys.findIndex(
        ({ id }) => id === action.payload.surveyId
      );

      if (surveyIndex !== -1)
        state.surveys[surveyIndex].questionGroupDeleted =
          action.payload.deleted;
    },
    setDefaultFalse: (state, action: PayloadAction<{ surveyId: number }>) => {
      let surveyIndex = state.surveys.findIndex(
        ({ id }) => id === action.payload.surveyId
      );

      if (surveyIndex !== -1) {
        state.surveys[surveyIndex].isDefault = false;
      }
    },
    setApiError: (state, action: PayloadAction<{ error: boolean }>) => {
      state.apiError = action.payload.error;
    },
    clearApiError: (state) => {
      state.apiError = initialState.apiError;
    },
  },
});

export const {
  setSurveys,
  addSurvey,
  updateSurvey,
  addQuestionGroup,
  updateQuestionGroup,
  updateSurveyQuestionGroups,
  setQuestionDeleted,
  setQuestionCreated,
  setQuestionGroupCreated,
  setQuestionGroupDeleted,
  setDefaultFalse,
  setApiError,
  clearApiError,
} = surveySlice.actions;

export default surveySlice.reducer;
