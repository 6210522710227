import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { UserResponseState, UpdateResponseTagsState } from "../../types";

interface UserResponsesState {
  userResponses: UserResponseState[];
  totalResponses?: number;
  apiError: boolean;
  emailError: boolean;
  newResponseError: boolean;
  searchTerm?: string;
  currentPage?: number;
}

const initialState: UserResponsesState = {
  userResponses: [],
  totalResponses: 0,
  apiError: false,
  emailError: false,
  newResponseError: false,
  searchTerm: "",
  currentPage: 1,
};

const userResponseSlice = createSlice({
  name: "userResponse",
  initialState,
  reducers: {
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setSearchTerm: (state, action: PayloadAction<string>) => {
      state.searchTerm = action.payload;
    },
    setUserResponses: (state, action: PayloadAction<UserResponseState[]>) => {
      state.userResponses = action.payload;
    },
    setTotalResponses: (state, action: PayloadAction<number>) => {
      state.totalResponses = action.payload;
    },
    updateUserResponse: (state, action: PayloadAction<UserResponseState>) => {
      const index = state.userResponses.findIndex(
        (userResponse) => userResponse.id === action.payload.id
      );
      if (index !== -1) state.userResponses[index] = action.payload;
    },
    updateUserResponseTags: (
      state,
      { payload }: PayloadAction<UpdateResponseTagsState>
    ) => {
      const index = state.userResponses.findIndex(
        (userResponse) => userResponse.id === Number(payload.id)
      );
      if (index !== -1)
        state.userResponses[index] = {
          ...state.userResponses[index],
          category: {
            maturityCategory: payload?.maturityCategory,
            temperatureCategory: payload?.temperatureCategory,
            techsTags: payload?.techsTags,
          },
        };
    },
    setApiError: (state, action: PayloadAction<{ error: boolean }>) => {
      state.apiError = action.payload.error;
    },
    clearApiError: (state) => {
      state.apiError = initialState.apiError;
    },
    setEmailError: (state, action: PayloadAction<{ error: boolean }>) => {
      state.apiError = action.payload.error;
    },
    clearEmailError: (state) => {
      state.apiError = initialState.apiError;
    },
    setNewResponseError: (state, action: PayloadAction<{ error: boolean }>) => {
      state.newResponseError = action.payload.error;
    },
    clearNewResponseError: (state) => {
      state.newResponseError = initialState.newResponseError;
    },
  },
});

export const {
  setCurrentPage,
  setSearchTerm,
  setUserResponses,
  setTotalResponses,
  updateUserResponse,
  updateUserResponseTags,
  setApiError,
  clearApiError,
  setEmailError,
  clearEmailError,
  setNewResponseError,
  clearNewResponseError,
} = userResponseSlice.actions;

export default userResponseSlice.reducer;
