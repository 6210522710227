import { useEffect, useMemo, useState } from "react";
import { IconButton } from "@material-ui/core";
import { useAppDispatch, useAppSelector } from "redux/store";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useParams } from "react-router-dom";

import {
  Container,
  TextContainer,
  Title,
  Tabs,
  Subtitle,
  ContentContainer,
  InputAdornment,
  Panel,
  Description,
  LeftContainer,
  RightContainer,
  GroupContainer,
  TabsContainer,
} from "./styles";
import {
  CHOOSE_QUESTION_TYPE,
  DAYS_AGO,
  LAST_EDIT,
  QUESTION_GROUPS,
  TODAY,
  UNTITLED_QUESTION_GROUP,
  UNTITLED_QUESTION,
  SUBTITLE,
  DELETE_QUESTION_GROUP_CONFIRMATION_MESSAGE,
  DELETION_WARNING_MESSAGE,
} from "../../utils/constants";
import { Tab } from "components/Tab";
import { AddItem } from "../../assets";
import PageWrapper from "../../components/pageWrapper";
import QuestionMatcherComponent from "../../components/questionMatcherComponent";
import { QuestionType } from "../../utils/utils";
import { createQuestion } from "./services/createQuestion";
import { getQuestionGroup } from "./services/getQuestionGroup";
import { createQuestionOption } from "./services/createQuestionOption";
import QuestionGroupDetailsCard from "components/questionGroupDetailsCard";
import { postQuestionGroup } from "./services/postQuestionGroup";
import QuestionTypeMatcherComponent from "../../components/questionTypeMatcherComponent";
import { QuestionState } from "../../types";
import { postReorder } from "./services/updateQuestion";
import { createPathRule } from "./services/createPathRule";
import {
  clearApiError,
  setQuestionCreated,
  setQuestionDeleted,
  setQuestionGroupCreated,
  setQuestionGroupDeleted,
  updateSurveyQuestionGroups,
} from "../../redux/slices/surveySlice";
import { ErrorModal, Modal } from "components";
import { deleteQuestionGroup } from "./services/deleteQuestionGroup";
import Header from "./header";

const SurveyDetails = () => {
  const { id } = useParams();
  const surveyId = useMemo(() => Number(id), [id]);
  const { TAG_TEXT, CHECKBOX, RADIO, DROPDOWN, DROPDOWN_INPUT, NUMERIC_INPUT } =
    QuestionType;
  const dispatch = useAppDispatch();
  const { surveys, apiError } = useAppSelector((state) => state.surveySlice);
  const survey = useMemo(
    () => surveys.find((survey) => survey.id === surveyId),
    [surveyId, surveys]
  );
  const questionGroups = useMemo(() => survey?.questionGroups, [survey]);

  const { questionTypes } = useAppSelector((state) => state.questionTypesSlice);
  const [activeTab, setActiveTab] = useState(0);
  const [currentQuestionGroupChanged, setCurrentQuestionGroupChanged] =
    useState(false);
  const currentQuestionGroup = useMemo(
    () =>
      questionGroups[
        activeTab < questionGroups.length
          ? activeTab
          : questionGroups.length - 1
      ],
    [activeTab, questionGroups]
  );
  const [questions, setQuestions] = useState<QuestionState[]>([]);
  const lastIndex = useMemo(
    () => (!!questions?.length ? questions[questions.length - 1]?.index : 0),
    [questions]
  );
  const [showModal, setShowModal] = useState(false);
  const [lastEdited, setLastEdited] = useState(-1);
  const [lastAction, setLastAction] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);

  const onClickQuestionType = async (type: QuestionType) => {
    setLastAction("onClickQuestionType");
    setSelectedItem(type);
    const questionCreated = await createQuestion({
      type,
      questionGroupId: currentQuestionGroup?.id,
      questionText: UNTITLED_QUESTION,
      index: lastIndex ? lastIndex + 1 : 1,
    });

    if (
      type === DROPDOWN ||
      type === TAG_TEXT ||
      type === CHECKBOX ||
      type === RADIO ||
      type === NUMERIC_INPUT ||
      type === DROPDOWN_INPUT
    ) {
      await createQuestionOption(questionCreated.id, { optionText: "" });
    }

    getQuestionGroup(currentQuestionGroup?.id, surveyId);
    dispatch(setQuestionCreated({ surveyId, created: true }));
  };

  const addNewTab = () => {
    setLastAction("addNewTab");
    const newQuestionGroup = {
      title: UNTITLED_QUESTION_GROUP,
      subtitle: SUBTITLE,
      question: "",
      questions: [],
      isFirst: questionGroups.length === 0,
      pathRule: [],
      surveyId,
    };
    postQuestionGroup(newQuestionGroup, surveyId);
    dispatch(setQuestionGroupCreated({ surveyId, created: true }));
  };

  const reOrder = async (result: any) => {
    setLastAction("reorder");
    if (!result.destination) return;
    const items = Array.from(questions);
    const [reorderedItem] = items.splice(result.source.index, 1);

    items.splice(result.destination.index, 0, reorderedItem);
    setQuestions(items);

    await postReorder(items, result);
  };

  useEffect(() => {
    if (currentQuestionGroup) {
      setCurrentQuestionGroupChanged(true);
      getQuestionGroup(currentQuestionGroup.id, surveyId);
    }
  }, [activeTab, currentQuestionGroup?.id]);

  useEffect(() => {
    if (currentQuestionGroup) {
      setQuestions(currentQuestionGroup.questions);
    }
  }, [currentQuestionGroup?.questions]);

  const createPathRuleAction = () => {
    const lastQuestionGroup = questionGroups[questionGroups.length - 1]?.id;
    const prevQuestionGroup = questionGroups[questionGroups.length - 2]?.id;

    if (survey?.questionGroupCreated && !!prevQuestionGroup) {
      setActiveTab(questionGroups.length - 1);
      createPathRule({
        questionGroupId: prevQuestionGroup,
        nextGroupId: lastQuestionGroup,
      });
      dispatch(setQuestionGroupCreated({ surveyId, created: false }));
    }

    if (survey?.questionGroupDeleted) {
      const newActiveTabIndex = Math.max(0, activeTab - 1);
      setActiveTab(newActiveTabIndex);
      dispatch(setQuestionGroupDeleted({ surveyId, deleted: false }));
    }
  };

  useEffect(() => {
    setLastAction("createPathRule");
    createPathRuleAction();
  }, [questionGroups.length]);

  useEffect(() => {
    if (survey?.questionDeleted) {
      setLastAction("reorder");
      postReorder(questions);
      dispatch(setQuestionDeleted({ surveyId, deleted: false }));
    }
  }, [questions?.length]);

  const handleDeleteQuestionGroup = () => {
    setLastAction("handleDeleteQuestionGroup");
    deleteQuestionGroup(currentQuestionGroup.id);
    dispatch(setQuestionGroupDeleted({ surveyId, deleted: true }));
    dispatch(
      updateSurveyQuestionGroups({
        surveyId,
        questionGroupId: currentQuestionGroup.id,
      })
    );
    setShowModal(false);
    if (questionGroups?.length === 1) {
      const newQuestionGroup = {
        title: UNTITLED_QUESTION_GROUP,
        subtitle: SUBTITLE,
        question: "",
        questions: [],
        isFirst: true,
        pathRule: [],
        surveyId,
      };
      postQuestionGroup(newQuestionGroup, surveyId);
      dispatch(setQuestionGroupCreated({ surveyId, created: true }));
    }
  };

  const getLastEdit = () => {
    let closestDate = Math.min();
    const today = new Date();

    questionGroups.forEach((group) => {
      const updatedAt = new Date(group.updated_at);

      let Difference_In_Time = today.getTime() - updatedAt.getTime();

      let Difference_In_Days = Math.round(
        Difference_In_Time / (1000 * 3600 * 24)
      );

      if (Difference_In_Days < closestDate || Difference_In_Days === 0)
        closestDate = Difference_In_Days;
    });

    return closestDate;
  };

  useEffect(() => {
    const lastQuestionGroupUpdated = getLastEdit();
    setLastEdited(lastQuestionGroupUpdated);
  }, [questionGroups]);

  const handleTryAgain = () => {
    switch (lastAction) {
      case "onClickQuestionType":
        onClickQuestionType(selectedItem);
        break;
      case "addNewTab":
        addNewTab();
        break;
      case "reorder":
        postReorder(questions);
        break;
      case "createPathRule":
        createPathRuleAction();
        break;
      case "handleDeleteQuestionGroup":
        handleDeleteQuestionGroup();
        break;
      default:
        break;
    }
  };

  return (
    <PageWrapper>
      <Container>
        <Header name={survey.name} surveyId={surveyId} />
        <ContentContainer>
          <LeftContainer>
            <TextContainer>
              <Subtitle>{CHOOSE_QUESTION_TYPE}</Subtitle>
            </TextContainer>
            <Panel>
              {questionTypes.map((item, index) => {
                if (item === "bifurcate") return null;
                return (
                  <QuestionTypeMatcherComponent
                    onClick={() => onClickQuestionType(item)}
                    questionType={item}
                    id={index}
                    key={item}
                  />
                );
              })}
            </Panel>
          </LeftContainer>
          <RightContainer>
            <TextContainer>
              <Title>{QUESTION_GROUPS}</Title>
              {lastEdited !== -1 &&
                (lastEdited === 0 ? (
                  <Description>
                    {LAST_EDIT} {TODAY}
                  </Description>
                ) : (
                  <Description>
                    {LAST_EDIT} {lastEdited} {DAYS_AGO}
                  </Description>
                ))}
            </TextContainer>

            <TabsContainer>
              <Tabs>
                {questionGroups.map((item, index) => (
                  <Tab
                    title={item.title}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    index={index}
                    key={item.id}
                    surveyId={surveyId}
                  />
                ))}
              </Tabs>
              <InputAdornment className="fixed">
                <IconButton onClick={addNewTab}>
                  <AddItem />
                </IconButton>
              </InputAdornment>
            </TabsContainer>
            {questionGroups?.length && (
              <GroupContainer>
                <QuestionGroupDetailsCard
                  id={currentQuestionGroup?.id}
                  initialData={currentQuestionGroup}
                  currentQuestionGroupChanged={currentQuestionGroupChanged}
                  setCurrentQuestionGroupChanged={
                    setCurrentQuestionGroupChanged
                  }
                  onClickDeleteButton={() => setShowModal(true)}
                  surveyId={surveyId}
                />

                {questions && (
                  <DragDropContext onDragEnd={reOrder}>
                    <Droppable droppableId="droppable">
                      {(provided) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {questions?.map(({ id, ...data }, index) => (
                            <Draggable
                              key={id}
                              draggableId={id.toString()}
                              index={index}
                            >
                              {(provided) => (
                                <QuestionMatcherComponent
                                  provided={provided}
                                  type={data.type}
                                  id={id}
                                  questionGroupId={currentQuestionGroup?.id}
                                  data={{ ...data, arrayIndex: index }}
                                  key={id}
                                  setQuestions={setQuestions}
                                  lastIndex={lastIndex}
                                />
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                )}
              </GroupContainer>
            )}
          </RightContainer>
        </ContentContainer>
        <Modal
          isOpen={showModal}
          handleClose={() => setShowModal(false)}
          handleDelete={handleDeleteQuestionGroup}
          message={DELETE_QUESTION_GROUP_CONFIRMATION_MESSAGE}
          description={DELETION_WARNING_MESSAGE}
        />
      </Container>
      {apiError && (
        <ErrorModal
          isOpen
          handleClose={() => dispatch(clearApiError())}
          handleTryAgain={handleTryAgain}
        />
      )}
    </PageWrapper>
  );
};

export default SurveyDetails;
