export const ADD_NEW_OPTION = "Add new option";
export const AI_ASSISTANT = "AI Assistant";
export const ADD_NEW_TAG = "Add new tag";
export const BRAND_LOGO = "Brand logo";
export const BRAND_ILLUSTRATIONS = "Brand illustrations";
export const COLOR_PALETTE = "Brand Color Palette";
export const CALENDLY = "Calendly";
export const DATE_FORMAT = "MM/DD/YYYY";
export const DELETE = "Delete";
export const DELETE_QUESTION_GROUP = "Delete question group";
export const DESIGN = "Design";
export const DRAG_AND_DROP = "Drag and drop or ";
export const UPLOAD_YOUR_SIGNATURE = "upload your signature";
export const DRAG_AND_DROP_LOGO = "Drag and drop your logo or ";
export const DUPLICATE = "Duplicate";
export const CHOOSE_QUESTION_TYPE = "Choose question type";
export const ENTER_EMAIL = "Enter your email";
export const ENTER_PASSWORD = "Enter your password";
export const ERROR = "Error";
export const FONT_FAMILY = "Font Family";
export const FILE_UPLOADED_SUCCESSFULLY = "File successfully uploaded";
export const INVALID_FILE_FORMAT_OTF_TTF =
  "Invalid file format. Please upload a .ttf or .otf file.";
export const INVALID_FILE_FORMAT_PNG_JPG =
  "Invalid file format. Please upload a .png or .jpg file.";
export const INVALID_FILE_FORMAT_SVG =
  "Invalid file format. Please upload a .svg file.";
export const LOGIN = "Log in";
export const LOGOUT = "Log out";
export const PASSWORD = "Password";
export const QUESTIONS = "Questions";
export const REMEMBER_ME = "Remember me";
export const REQUIRED = "Required";
export const RESPONSES = "Responses";
export const SURVEY = "Survey";
export const SHOW_THE_CALENDLY_MODAL_IN_THE_HOME_PAGE =
  "Show the calendly modal in the home page.";
export const QUESTION_GROUPS = "Question groups";
export const SURVEY_DETAILS = "Survey";
export const EMAIL = "Email";
export const SENDER_EMAIL = "Sender email";
export const RECEIVER_EMAIL = "Receiver email";
export const UPDATED = "Updated";
export const OPTION_PLACEHOLDER = "Option label";
export const RESET_STYLES = "Reset default styles";
export const PRIMARY_BUTTON = "Primary button";
export const SECONDARY_BUTTON = "Secondary button";
export const TAGS = "Tags";
export const TEXT_COLOR = "Text color";
export const TITLE_COLOR = "Title color";
export const INVALID_FILE_SIZE = "Invalid file size";
export const THE_SIGNATURE_MUST_BE_150_500 = "The signature must be 150x500";
export const TYPOGRAPHY = "Typography";
export const LINKS = "Links";
export const CANCEL = "Cancel";
export const DELETE_QUESTION_GROUP_CONFIRMATION_MESSAGE =
  "You are about to delete this question group.";
export const DELETION_WARNING_MESSAGE =
  "Questions will be deleted, but responses history will remain in the database.";
export const TYPE_YOUR_QUESTION_GROUP_TITLE = "Type your question group title";
export const TYPE_YOUR_QUESTION_GROUP_SUBTITLE =
  "Type your question group subtitle";
export const TYPE_YOUR_QUESTION = "Type your question";
export const TYPE_YOUR_QUESTION_SUBTITLE = "Type your question subtitle";
export const UPLOAD_FONT = "Upload font";
export const UPLOADING_FILE = "Uploading file...";
export const UPLOAD_LINK = "upload your file";
export const ONLY_PNG_JPG_FILES = "Only PNG & JPG. Max 5mb";
export const PREVIEW = "Preview";
export const ENTER_YOUR_TEXT_HERE = "Enter your text here...";
export const BACK = "Back";
export const NEXT = "Next";
export const PARTNERS = " Partners and Customers";
export const ABOUT_SERVICE =
  "Tell us more about the service you are looking for.";
export const COLLABORATORS =
  " Are there any other collaborators or partners you would like us to know about?";
export const TARGET_USERS = " Who are the target users [use-case actors] ? *";
export const ADD_TAG = "Press enter to add the tag";
export const ESTIMATED_BUDGET =
  "What is the estimated budget assigned for developing your project?*";
export const OK = "Ok";
export const MOBILE_MESSAGE = "This site is designed for desktop use.";
export const ACCESS = "Please access from a computer.";
export const BACKGROUND = "Background";
export const LOGO_PNG = "Logo.png";
export const SETTINGS = "Settings";
export const NDA = "NDA";
export const SHOW_AND_SEND_STANDARD_NDA = "Show and send standard NDA.";
export const SHOW_THE_AI_ASSISTANT =
  "Shows the AI ​​assistant for intelligent form text filling.";
export const SIGNATURE_SVG = "Signature.svg";
export const ENTER_YOUR_NAME = "Enter your name";
export const NAME = "Name";
export const ENTER_YOUR_LAST_NAME = "Enter your last name";
export const LAST_NAME = "Last name";
export const ENTER_YOUR_COMPANY_NAME = "Enter your company name";
export const COMPANY_NAME = "Company name";
export const FULL_NAME = "Full name";
export const FONT = "Font";
export const LOGO_CAVEAT =
  "Please upload an image with transparent background and sized to 180x36 pixels.";
export const BOLD = "Bold";
export const SEMI_BOLD = "Semi-Bold";
export const REGULAR = "Regular";
export const HTTP = "http://";
export const HOST_URL = "Host URL";
export const ENTER_TOKEN = "Enter token";
export const AUTHENTICATION_TOKEN = "Authentication Token";
export const FINAL_PDF = "Final PDF";
export const DATE_FORMAT_TEXT = "Date Format";
export const DATE_FORMAT_SUBTITLE =
  "Specify the date format for date-type questions and filtering responses";
export const SEND_THE_USER_A_PDF_WITH_THE_ANSWERS =
  "Set up the emails you wish will send and receive the PDF with the answers.";
export const EXAMPLE_EMAIL = "example@email.com";
export const LAST_EDIT = "Last edit was made";
export const DAYS_AGO = "days ago";
export const TODAY = "today";
export const OPS = "Oops!";
export const OPEN_AI_KEY = "Open AI API Key";
export const ERROR_MODAL = "Something went wrong, please try again.";
export const AI_APIKEY_ERROR = "Please enter a valid API key.";
export const TRY_AGAIN = "Try again";
export const OOPS = "Oops!";
export const GO_BACK = "Go back";
export const PAGE_NOT_FOUND = "Page not found";
export const SORRY_WE_CANT_FIND_THE_PAGE_YOU_ARE_LOOKING_FOR =
  "Sorry we can't find the page you are looking for.";
export const EMAIL_WARNING = "Remeber to use  valid and existing emails.";
export const LAYOUT = "Horizontal layout";
export const SEARCH = "Search...";
export const NEW_RESPONSE = "Add new response";
export const SEND = "Send";
export const SEND_PDF = "Send PDF";
export const GENERATED_LINK = "Generated link";
export const LINK_FOR_USER = "Link for user";
export const COPY = "Copy";
export const UNTITLED_QUESTION = "Untitled question";
export const UNTITLED_QUESTION_GROUP = "Untitled question group";
export const SUBTITLE = "Subtitle";
export const ANSWERS = "Answers";
export const CLIENT_INFORMATION = "Client information";
export const COMPANY = "Company:";
export const LASTNAME = "Lastname:";
export const DOWNLOAD = "Download";
export const SURVEY_ANSWERS = "Survey answers";
export const USER_RESPONSES = "User Responses";
export const CREATE = "Create";
export const PLEASE_COMPLETE_INFORMATION =
  "Please complete the following information.";
export const SAVE_EDITION = "Save edition";
export const TYPE_TAG = "Type tags options";
export const CLICK_TO_ADD = "Click to add";
export const EFFECTUS_BACKGROUND_LOGO_PATH = "/pdfBMCLogo.png";
export const NONE = "None";
export const EDITED_BY_ADMIN = "Edited by admin";
export const COPIED_TO_CLIPBOARD = "Copied to clipboard!";
export const HOME_PAGE_TEXT_COLOR = "Home page text color";
export const CHOOSE_YOUR_FIT_EDGES = "Choose your fit edges can not be deleted";
export const RECEIVE_EMAIL =
  "You'll receive an email with the information provided.";
export const THANK_YOU = "Thank you, [NAME]!";
export const ILLUSTRATIONS = "Illustrations";
export const LOADING = "Loading";
export const SEND_EMAIL = "Sending email...";
export const SUCCESS = "Success";
export const DRAG_AND_DROP_ILLUSTRATION = "Drag and drop your illustration or ";
export const SUCCESS_ICON_DESCRIPTION =
  "Please upload an image with transparent background and sized to 370x370 pixels.";
export const LOADING_ICON_DESCRIPTION =
  "Please upload an image with transparent background and sized to 149x149 pixels.";
export const ENTER_YOUR_OPENAI_KEY = "Enter your OPEN AI API key";
export const MATURITY = "Maturity";
export const TEMPERATURE = "Temperature";
export const TECH = "Tech";
export const SELECT_TAGS = "Please select tags to classify the response";
export const CREATE_SURVEY = "Create survey";
export const NEW_SURVEY_NAME = "Untitled Survey";
export const SURVEY_DELETION_WARNING_MESSAGE =
  "Responses will be removed as well.";
export const DELETE_SURVEY_CONFIRMATION_MESSAGE =
  "You are about to delete the survey:";
export const SET_AS_DEFAULT = "Set as default";
export const DELETE_SURVEY = "Delete survey";
export const PATH_RULES = "Path rules";
export const MISSING_CHOOSE_YOUR_FIT_ANSWER = "Missing Choose your fit answer";
