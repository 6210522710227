import styled from "styled-components";
import { Select } from "@mui/material";
import { selectClasses } from "@mui/material/Select";

export const StyledSelect = styled(Select)<{ isModal?: boolean }>(
  ({ theme, isModal }) => {
    const {
      customPalette: {
        grey,
        hover,
        textField: {
          default: { border },
        },
      },
      colors: { white },
    } = theme;

    return {
      minWidth: 200,
      width: isModal ? "100%" : "unset",
      [`& .${selectClasses.select}`]: {
        background: white,
        display: "flex",
        color: grey,
        borderRadius: "7px",
        border: `2px solid ${border}`,
        paddingLeft: "16px",
        paddingRight: "20px",
        paddingTop: "10px",
        paddingBottom: "10px",
        alignItems: "center",
        "&:focus": {
          borderRadius: "7px",
          border: `2px solid ${border}`,
        },
        "&:hover": {
          borderRadius: "7px",
          border: `2px solid ${hover}`,
        },
      },
      [`& .${selectClasses.icon}`]: {
        right: "12px",
        top: "12px",
        paddingLeft: "2px",
      },
    };
  }
);

export const StyledLabel = styled("p")(({ theme }) => {
  return {
    wordWrap: "break-word",
    whiteSpace: "wrap",
    overflow: "hidden",
  };
});

export const ValueContainer = styled("div")(() => {
  return {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    paddingRight: "20px",
  };
});
