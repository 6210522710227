import React, { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import { useNavigate } from "react-router-dom";

import PageWrapper from "../../components/pageWrapper";
import {
  ButtonsCell,
  Container,
  LeftRowContainer,
  IconButton,
  TableCell,
  TableHead,
  TableRow,
  Text,
  TextContainer,
  Title,
  SubTitle,
  BarContainer,
  TagsContainer,
  LeftContainer,
} from "./styles";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { EmailIcon, ResponseIcon, SearchIcon } from "../../assets";
import {
  COMPANY_NAME,
  EMAIL,
  RESPONSES,
  FULL_NAME,
  SEARCH,
  NEW_RESPONSE,
  SEND,
  ENTER_EMAIL,
  SEND_PDF,
  CREATE,
  PLEASE_COMPLETE_INFORMATION,
  GENERATED_LINK,
  LINK_FOR_USER,
  TAGS,
} from "../../utils/constants";
import { Button, ErrorModal, TextInput } from "../../components";
import RangeDatepicker from "../../components/rangeDatePicker";
import PageCounter from "../../components/pageCounter";
import { filterResponses } from "./services/filterResponses";
import {
  clearApiError,
  clearEmailError,
  clearNewResponseError,
  setUserResponses,
  setSearchTerm,
  setCurrentPage,
} from "redux/slices/userResponseSlice";
import ResponseModal from "../../components/responseModal";
import { sendEmail } from "./services/sendEmail";
import { InputState, SurveyState } from "types";
import { sendNewResponse, sendNewUser } from "./services/sendNewResponse";
import CopiedToClipboardModal from "components/copiedModal";
import { filterTagsOptions } from "../../utils/utils";
import StyledSelect from "./select";
import TagsList from "components/tagsList";
import CustomSelect, { Option } from "components/customSelect";
import { enableLinkGeneration } from "utils/methods/linkUtils";
import { handleLinkGeneration } from "utils/methods/linkUtils";

const RESPONSES_PER_PAGE = 8;

const Responses = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isVisible, setIsVisible] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [company, setCompany] = useState("");
  const [responseSurveyId, setResponseSurveyId] = useState(Number);
  const [pdfModalOpen, setPdfModalOpen] = useState(false);
  const [linkModalOpen, setLinkModalOpen] = useState(false);
  const [newResponseModalOpen, setNewResponseModalOpen] = useState(false);
  const [userResponseIndex, setUserResponseIndex] = useState(-1);
  const [linkForUser, setLinkForUser] = useState("");
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);
  const [selectedTags, setSelectedTags] = useState<number[]>([]);

  const { id } = useAppSelector((state) => state.companySlice);
  const { surveys } = useAppSelector((state) => state.surveySlice);

  const {
    userResponses,
    totalResponses,
    apiError,
    emailError,
    newResponseError,
    searchTerm,
    currentPage,
  } = useAppSelector((state) => state.userResponseSlice);
  const {
    name: adminName,
    emailSender,
    companyName,
    clientURL,
  } = useAppSelector((state) => state.authSlice);
  const { logo, primaryColor } = useAppSelector(
    (state) => state.companySlice.branding
  );

  const [term, setTerm] = useState(searchTerm);
  const [debouncedSearchTerm] = useDebounce(term, 500);
  const [debouncedSelectedTags] = useDebounce(selectedTags, 500);

  const defaultSurvey = surveys.find((survey) => survey.isDefault);

  // To-do: remove survey[0] when default is implemented
  const [selectedSurvey, setSelectedSurvey] = useState<SurveyState>(
    defaultSurvey || surveys[0]
  );
  const hasQuestions = () => {
    return selectedSurvey.questionGroups.find(
      (questionGroup) => questionGroup.questions.length > 0
    );
  };

  const handleContainerClick = (event: React.MouseEvent) => {
    const target = event.target as HTMLElement;

    if (isVisible && !target.closest(".rangeDatePicker")) setIsVisible(false);
  };

  useEffect(() => {
    if (debouncedSearchTerm !== searchTerm) {
      dispatch(setSearchTerm(debouncedSearchTerm));
      dispatch(setCurrentPage(1));
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    let formattedStartDate;
    let formattedEndDate;
    if (startDate && endDate) {
      formattedStartDate = new Date(
        startDate.setHours(0, 0, 0, 0)
      ).toISOString();
      formattedEndDate = new Date(
        endDate.setHours(23, 59, 59, 999)
      ).toISOString();
    }
    const selectedTagLabels = selectedTags.map((tag) => filterTagsOptions[tag]);
    if (debouncedSelectedTags !== selectedTags) dispatch(setCurrentPage(1));
    filterResponses({
      page: currentPage,
      term: searchTerm,
      surveyId: selectedSurvey.id,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      tags: selectedTagLabels,
    });
  }, [
    currentPage,
    searchTerm,
    startDate,
    endDate,
    selectedTags,
    selectedSurvey,
  ]);

  const sendResponsePdf = (index: number) => {
    setPdfModalOpen(true);
    setUserResponseIndex(index);
  };

  const sendEmailRequest = () => {
    if (userResponses[userResponseIndex]?.pdfUrl)
      sendEmail({
        name: adminName,
        emailReceiver: email,
        emailSender,
        adminCompanyName: companyName,
        sendBMCPDF: "true",
        pdfFileOrUrl: userResponses[userResponseIndex].pdfUrl,
        company: userResponses[userResponseIndex].user.company,
        companyLogo: logo,
        primaryColor,
        isEffectus: companyName === "Effectus Software",
      });
  };

  const pdfModalInputs: InputState[] = [
    {
      label: ENTER_EMAIL,
      value: email,
      placeholder: EMAIL,
      setValue: setEmail,
    },
  ];

  const linkModalInputs: InputState[] = [
    {
      label: LINK_FOR_USER,
      value: linkForUser,
      placeholder: LINK_FOR_USER,
    },
  ];

  const newResponseModalInputs: InputState[] = [
    {
      label: "Survey",
      placeholder: "Select a survey",
      value: selectedSurvey.name || "",
      setValue: setResponseSurveyId,
      isSelect: true,
      selectOptions: surveys.reduce<Option[]>(
        (acc, survey) => [...acc, { key: survey.id, value: survey.name }],
        []
      ),
    },
    {
      label: FULL_NAME,
      value: fullName,
      placeholder: "John Doe",
      setValue: setFullName,
    },
    {
      label: EMAIL,
      value: email,
      placeholder: "jDoe@gmail.com",
      setValue: setEmail,
    },
    {
      label: COMPANY_NAME,
      value: company,
      placeholder: "Company",
      setValue: setCompany,
    },
  ];

  const handleNewResponse = async () => {
    const user = await sendNewUser({
      name: fullName.split(" ")[0],
      surname: fullName.split(" ")[1],
      email,
      company,
    });

    if (user) {
      const createdResponse = await sendNewResponse({
        companyId: id,
        user,
        surveyId: responseSurveyId,
      });
      if (createdResponse) {
        dispatch(setUserResponses([...userResponses, createdResponse]));
        navigate(`/responses/${createdResponse.id}`);
      }
    }
  };

  const handleLinkCopy = async () => {
    await navigator.clipboard.writeText(linkForUser);
    setCopiedToClipboard(true);
    setTimeout(() => setCopiedToClipboard(false), 2000);
  };

  const handleTagClick = (value: number) => {
    setSelectedTags((prevTags) => {
      if (prevTags.includes(value))
        return prevTags.filter((tag) => tag !== value);
      else return [...prevTags, value];
    });
  };

  return (
    <PageWrapper>
      {copiedToClipboard && <CopiedToClipboardModal />}
      <ResponseModal
        isOpen={pdfModalOpen}
        title={SEND_PDF}
        inputs={pdfModalInputs}
        handleClose={() => {
          setPdfModalOpen(false);
          setEmail("");
        }}
        handleButton={() => {
          sendEmailRequest();
          setPdfModalOpen(false);
          setEmail("");
        }}
        buttonLabel={SEND}
      />
      <ResponseModal
        isOpen={linkModalOpen}
        title={GENERATED_LINK}
        inputs={linkModalInputs}
        handleClose={() => setLinkModalOpen(false)}
        handleButton={handleLinkCopy}
        hasCopyButton
        disabled
      />
      <ResponseModal
        isOpen={newResponseModalOpen}
        title={NEW_RESPONSE}
        subtitle={PLEASE_COMPLETE_INFORMATION}
        inputs={newResponseModalInputs}
        selectedSurveyId={selectedSurvey.id}
        handleClose={() => {
          setNewResponseModalOpen(false);
          setFullName("");
          setEmail("");
          setCompany("");
        }}
        handleButton={() => {
          handleNewResponse();
          setNewResponseModalOpen(false);
          setFullName("");
          setEmail("");
          setCompany("");
        }}
        buttonLabel={CREATE}
      />
      <Container onClick={handleContainerClick}>
        <TextContainer>
          <Title>{RESPONSES}</Title>
          <SubTitle>-</SubTitle>
          <SubTitle>{selectedSurvey.name}</SubTitle>
        </TextContainer>
        <BarContainer>
          <LeftContainer>
            <CustomSelect
              selectedValues={[
                { key: selectedSurvey.id, value: selectedSurvey.name },
              ]}
              handleValueClick={(surveyId: number) => {
                const selected = surveys.find(
                  (survey) => survey.id === surveyId
                );
                if (selected) setSelectedSurvey(selected);
              }}
              options={surveys.reduce(
                (acc, survey) => ({ ...acc, [survey.id]: survey.name }),
                {}
              )}
            />
            <TextInput
              placeholder={SEARCH}
              value={term}
              showErrorLabel={false}
              icon={SearchIcon}
              onChange={(e) => setTerm(e.target.value)}
            />
            <RangeDatepicker
              isVisible={isVisible}
              setIsVisible={setIsVisible}
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />
            <StyledSelect
              selectedTags={selectedTags}
              handleTagClick={handleTagClick}
            />
          </LeftContainer>
          <Button
            label={NEW_RESPONSE}
            variantType="primary"
            height={48}
            onClick={() => setNewResponseModalOpen(true)}
            minWidth={200}
            disabled={!hasQuestions()}
          />
        </BarContainer>
        <TableHead>
          <TableCell className="bold">{FULL_NAME}</TableCell>
          <TableCell className="bold">{EMAIL}</TableCell>
          <TableCell className="bold">{COMPANY_NAME}</TableCell>
          <TableCell className="bold">{TAGS}</TableCell>
        </TableHead>

        {userResponses.map(
          (
            {
              id,
              user: { name, surname, email, company },
              pdfUrl,
              category: { maturityCategory, temperatureCategory, techsTags },
              questionResponses,
            },
            index
          ) => (
            <TableRow key={index} onClick={() => navigate(`/responses/${id}`)}>
              <LeftRowContainer>
                <TableCell>
                  <Text className="bold">
                    {name} {surname}
                  </Text>
                </TableCell>
                <TableCell>
                  <Text>{email}</Text>
                </TableCell>
                <TableCell>
                  <Text>{company}</Text>
                </TableCell>

                <TagsContainer id="tags-container">
                  <TagsList
                    maturityCategory={maturityCategory}
                    temperatureCategory={temperatureCategory}
                    techsTags={techsTags}
                  />
                </TagsContainer>
              </LeftRowContainer>
              <ButtonsCell>
                {pdfUrl && (
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      sendResponsePdf(index);
                    }}
                  >
                    <EmailIcon />
                  </IconButton>
                )}
                <IconButton
                  disabled={!enableLinkGeneration(userResponses, index)}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (enableLinkGeneration(userResponses, index)) {
                      handleLinkGeneration(
                        id,
                        selectedSurvey.name,
                        clientURL,
                        setLinkForUser,
                        setLinkModalOpen
                      );
                    }
                  }}
                >
                  <ResponseIcon />
                </IconButton>
              </ButtonsCell>
            </TableRow>
          )
        )}
        <PageCounter
          currentPage={currentPage}
          pages={Math.ceil(totalResponses / RESPONSES_PER_PAGE)}
        />
      </Container>
      {(apiError || emailError || newResponseError) && (
        <ErrorModal
          isOpen
          handleClose={() =>
            apiError
              ? dispatch(clearApiError())
              : emailError
              ? dispatch(clearEmailError())
              : dispatch(clearNewResponseError())
          }
          handleTryAgain={apiError ? null : sendEmailRequest()}
        />
      )}
    </PageWrapper>
  );
};

export default Responses;
