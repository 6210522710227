/* eslint-disable @typescript-eslint/no-empty-interface */
import { createTheme } from "@mui/material/styles";

interface CustomTheme {
  colors: {
    transparent: string;
    darkGrey: string;
    blackGrey: string;
    white: string;
  };

  customFonts: {
    poppinsLight: string;
    poppins: string;
    poppinsBold: string;
    poppinsExtraBold: string;
    poppinsSemiBold: string;
  };

  customPalette: {
    // Palette of Colors
    primary: string;
    secondary: string;
    secondaryDark: string;
    hover: string;
    disabled: string;
    tagsDefault: string;
    tagsActive: string;
    darkGrey: string;
    darkMediumGrey: string;
    mediumGrey: string;
    mediumLightGrey: string;
    lightGrey: string;
    grey: string;
    secondGray: string;
    white: string;
    error: string;
    lightRed: string;
    success: string;
    lightGreen: string;
    warning: string;
    semiTransparent: string;
    darkTransparent: string;
    dottedBorder: string;
    pageBorder: string;
    pageColor: string;
    responseColor: string;
    // Palette of Colors

    categorizationTags: {
      mature: string;
      barelyMature: string;
      immature: string;
      hot: string;
      warm: string;
      cold: string;
      frontend: string;
      backend: string;
      mobile: string;
      web: string;
    };

    // Palette for Components
    button: {
      primary: {
        main: string;
        hover: string;
        click: string;
        disable: string;
      };
      secondary: {
        main: string;
        hover: string;
        click: string;
        disable: string;
      };
      text: {
        primary: string;
        secondary: string;
      };
      border: {
        primary: string;
        secondary: string;
      };
      shadow: {
        primary: string;
        secondary: string;
        disableSecondary: string;
      };
    };

    tag: {
      normal: {
        background: string;
        text: string;
        boxShadow: string;
      };
      active: {
        background: string;
        text: string;
        boxShadow: string;
      };
      hover: {
        background: string;
        text: string;
        boxShadow: string;
      };
    };

    textField: {
      default: {
        border: string;
        text: string;
      };
      hover: {
        border: string;
        text: string;
      };
      active: {
        border: string;
        text: string;
      };
      error: {
        border: string;
        text: string;
        textError: string;
      };
      disabled: {
        border: string;
        text: string;
      };
    };

    home: {
      textColor: string;
    };

    feedbackMessage: {
      success: {
        background: string;
      };
      error: {
        background: string;
      };
    };

    textAreaInput: {
      border: string;
      borderHover: string;
      placeholder: string;
      activeText: string;
    };

    radio: {
      normal: {
        border: string;
        background: string;
      };
      hover: {
        border: string;
        background: string;
      };
      filled: {
        border: string;
        background: string;
      };
    };

    card: {
      boxShadow: string;
    };

    preview: {
      boxShadow: string;
    };
    // Palette for Components
  };

  customSizes: {
    // Sizes of Elements
    heading: {
      h1: {
        fontSize: string;
        fontWeight: number | string;
        lineHeight: string;
      };
      h2: {
        fontSize: string;
        fontWeight: number | string;
        lineHeight: string;
      };
      h3: {
        fontSize: string;
        fontWeight: number | string;
        lineHeight: string;
      };
      h4: {
        fontSize: string;
        fontWeight: number | string;
        lineHeight: string;
      };
      h5: {
        fontSize: string;
        fontWeight: number | string;
        lineHeight: string;
      };
      h6: {
        fontSize: string;
        fontWeight: number | string;
        lineHeight: string;
      };
    };
    uploadStatus: {
      fontSize: string;
      fontWeight: number | string;
      lineHeight: string;
    };
    body: {
      title1: {
        semibold: {
          fontSize: string;
          fontWeight: number | string;
          lineHeight: string;
        };
        regular: {
          fontSize: string;
          fontWeight: number | string;
          lineHeight: string;
        };
      };
      title2: {
        semibold: {
          fontSize: string;
          fontWeight: number | string;
          lineHeight: string;
        };
        regular: {
          fontSize: string;
          fontWeight: number | string;
          lineHeight: string;
        };
      };
      description: {
        semibold: {
          fontSize: string;
          fontWeight: number | string;
          lineHeight: string;
        };
        medium: {
          fontSize: string;
          fontWeight: number | string;
          lineHeight: string;
        };
        regular: {
          fontSize: string;
          fontWeight: number | string;
          lineHeight: string;
        };
      };
      smallDescription: {
        semibold: {
          fontSize: string;
          fontWeight: number | string;
          lineHeight: string;
        };
        medium: {
          fontSize: string;
          fontWeight: number | string;
          lineHeight: string;
        };
        regular: {
          fontSize: string;
          fontWeight: number | string;
          lineHeight: string;
        };
      };
      sXDescription: {
        semibold: {
          fontSize: string;
          fontWeight: number | string;
          lineHeight: string;
        };
        medium: {
          fontSize: string;
          fontWeight: number | string;
          lineHeight: string;
        };
        regular: {
          fontSize: string;
          fontWeight: number | string;
          lineHeight: string;
        };
      };
    };
    // Sizes of Elements

    // Sizes Components
    button: {
      primary: {
        fontSize: string;
        fontWeight: number | string;
        lineHeight: string;
      };
      secondary: {
        fontSize: string;
        fontWeight: number | string;
        lineHeight: string;
      };
    };

    textField: {
      text: {
        fontSize: string;
        fontWeight: number | string;
        lineHeight: string;
      };
      label: {
        fontSize: string;
        fontWeight: number | string;
        lineHeight: string;
      };
      placeholder: {
        fontSize: string;
        fontWeight: number | string;
        lineHeight: string;
        fontFamily: string;
      };
    };

    tag: {
      text: {
        fontSize: string;
        fontWeight: number | string;
        lineHeight: string;
      };
    };

    home: {
      desktop: {
        title: {
          fontSize: string;
          fontWeight: number | string;
          lineHeight: string;
        };
        description: {
          fontSize: string;
          fontWeight: number | string;
          lineHeight: string;
        };
      };

      mobile: {
        title: {
          fontSize: string;
          fontWeight: number | string;
          lineHeight: string;
        };
        description: {
          fontSize: string;
          fontWeight: number | string;
          lineHeight: string;
        };
      };
    };

    textAreaInput: {
      desktop: {
        placeholder: {
          fontSize: string;
          fontWeight: number | string;
          lineHeight: string;
        };
      };
      mobile: {
        placeholder: {
          fontSize: string;
          fontWeight: number | string;
          lineHeight: string;
        };
      };
    };

    radioComponent: {
      text: {
        fontSize: string;
        fontWeight: number | string;
        lineHeight: string;
      };
    };

    card: {
      desktop: {
        title: {
          fontSize: string;
          fontWeight: number | string;
          lineHeight: string;
        };
        text: {
          fontSize: string;
          fontWeight: number | string;
          lineHeight: string;
        };
      };

      mobile: {
        title: {
          fontSize: string;
          fontWeight: number | string;
          lineHeight: string;
        };
        text: {
          fontSize: string;
          fontWeight: number | string;
          lineHeight: string;
        };
      };
    };

    preview: {
      subtitle: {
        fontSize: string;
        fontWeight: number;
        lineHeight: string;
      };
      question: { fontSize: string; fontWeight: number; lineHeight: string };
      link: { fontSize: string; fontWeight: number; lineHeight: string };
      tag: { fontSize: string; fontWeight: number; lineHeight: string };
      label: { fontSize: string; fontWeight: number; lineHeight: string };
    };
    tagLabel: { fontSize: string; fontWeight: number; lineHeight: string };
    // Sizes Components
  };
}

// allow configuration using `createTheme`
declare module "@mui/material/styles" {
  interface Theme extends CustomTheme {}

  // allow configuration using `createTheme`
  interface ThemeOptions extends CustomTheme {}
}
const theme = createTheme({
  colors: {
    transparent: "transparent",
    darkGrey: "#282C34",
    blackGrey: "#20232A",
    white: "#FFFFFF",
  },
  // Fonts

  // Default font
  typography: {
    fontFamily: "Poppins-Regular",
  },

  customFonts: {
    poppinsLight: "Poppins-Light",
    poppins: "Poppins-Regular",
    poppinsBold: "Poppins-Bold",
    poppinsExtraBold: "Poppins-ExtraBold",
    poppinsSemiBold: "Poppins-SemiBold",
  },
  // Fonts

  customPalette: {
    // Palette of Colors
    primary: "#1B1B1B",
    secondary: "#5D5DF6",
    secondaryDark: "#3F3FFF",
    hover: "#8686FE",
    disabled: "#CECEFC",
    tagsDefault: "#EDEDFF",
    tagsActive: "#CECEFF",
    darkGrey: "#3E3E3E",
    darkMediumGrey: "#535353",
    mediumGrey: "#BABABA",
    mediumLightGrey: "#DCDCDC",
    lightGrey: "#E1E1E1",
    grey: "#757575",
    secondGray: "#626262",
    white: "#FFFFFF",
    error: "#FE1A38",
    lightRed: "#FFE8EB",
    success: "#009944",
    lightGreen: "#E6F5EC",
    warning: "#ED8C00",
    semiTransparent: "#23222275",
    darkTransparent: "#F5F5F5",
    dottedBorder: "#A0A0A0",
    pageBorder: "#DFE3E8",
    pageColor: "#212B36",
    responseColor: "#F3F3F3",
    // Palette of Colors

    categorizationTags: {
      mature: "#2C473E",
      barelyMature: "#6A8E4E",
      immature: "#D0E4B3",
      hot: "#F56D43",
      warm: "#FEE08E",
      cold: "#4575B5",
      frontend: "#D6D6EF",
      backend: "#4D4D8D",
      web: "#6464BC",
      mobile: "#7979FF",
    },

    // Palette for Components
    button: {
      primary: {
        main: "#5D5DF6",
        hover: "#8686FE",
        click: "#3F3FFF",
        disable: "#CECEFC",
      },
      secondary: {
        main: "#FFFFFF",
        hover: "#FFFFFF",
        click: "#FFFFFF",
        disable: "#FFFFFF",
      },
      text: {
        primary: "#FFFFFF",
        secondary: "#5D5DF6",
      },
      border: {
        primary: "none",
        secondary: "1px solid #5D5DF6",
      },
      shadow: {
        primary: "none",
        secondary: "0px 0px 10px 0px rgba(120,121,241,0.4)",
        disableSecondary: "none",
      },
    },

    tag: {
      normal: {
        background: "#EDEDFF",
        text: "#535353",
        boxShadow: "none",
      },
      active: {
        background: "#CECEFF",
        text: "#535353",
        boxShadow: "none",
      },
      hover: {
        background: "#EDEDFF",
        text: "#535353",
        boxShadow: "0px 0px 4px rgba(134, 134, 254, 0.56)",
      },
    },

    textField: {
      default: {
        border: "#D5D2D5",
        text: "#535353",
      },
      hover: {
        border: "#8686FE",
        text: "#535353",
      },
      active: {
        border: "#8686FE",
        text: "#3E3E3E",
      },
      error: {
        border: "#FE1A38",
        text: "#535353",
        textError: "#FE1A38",
      },
      disabled: {
        border: "#D5D2D5",
        text: "#C0C0C0",
      },
    },

    home: {
      textColor: "#FFFFFF",
    },

    feedbackMessage: {
      success: {
        background: "#E6F5EC",
      },
      error: {
        background: "#FFE8EB",
      },
    },

    textAreaInput: {
      border: "#DCDCDC",
      borderHover: "#8686FE",
      placeholder: "#535353",
      activeText: "#1B1B1B",
    },

    radio: {
      normal: {
        border: "#BABABA",
        background: "#FFFFFF",
      },
      hover: {
        border: "#5D5DF6",
        background: "#FFFFFF",
      },
      filled: {
        border: "#5D5DF6",
        background: "#5D5DF6",
      },
    },

    card: {
      boxShadow: "0px 0px 8px 0px #ABABAB40",
    },
    preview: {
      boxShadow: "0px 0px 8px 0px rgba(171,171,171,0.24)",
    },
    // Palette for Components
  },
  customSizes: {
    // Sizes of Elements
    heading: {
      h1: {
        fontSize: "96px",
        fontWeight: 800,
        lineHeight: "144px",
      },
      h2: {
        fontSize: "80px",
        fontWeight: 600,
        lineHeight: "96px",
      },
      h3: {
        fontSize: "72px",
        fontWeight: 800,
        lineHeight: "108px",
      },
      h4: {
        fontSize: "64px",
        fontWeight: 700,
        lineHeight: "96px",
      },
      h5: {
        fontSize: "48px",
        fontWeight: 700,
        lineHeight: "75.12px",
      },
      h6: {
        fontSize: "32px",
        fontWeight: 700,
        lineHeight: "48px",
      },
    },
    uploadStatus: {
      fontSize: "13px",
      fontWeight: 400,
      lineHeight: "16px",
    },
    body: {
      title1: {
        semibold: {
          fontSize: "24px",
          fontWeight: 600,
          lineHeight: "40px",
        },
        regular: {
          fontSize: "24px",
          fontWeight: 400,
          lineHeight: "40px",
        },
      },
      title2: {
        semibold: {
          fontSize: "20px",
          fontWeight: 600,
          lineHeight: "32px",
        },
        regular: {
          fontSize: "20px",
          fontWeight: 400,
          lineHeight: "32px",
        },
      },
      description: {
        semibold: {
          fontSize: "17px",
          fontWeight: 600,
          lineHeight: "32px",
        },
        medium: {
          fontSize: "17px",
          fontWeight: 500,
          lineHeight: "32px",
        },
        regular: {
          fontSize: "17px",
          fontWeight: 400,
          lineHeight: "32px",
        },
      },
      smallDescription: {
        semibold: {
          fontSize: "15px",
          fontWeight: 600,
          lineHeight: "24px",
        },
        medium: {
          fontSize: "15px",
          fontWeight: 500,
          lineHeight: "24px",
        },
        regular: {
          fontSize: "15px",
          fontWeight: 400,
          lineHeight: "24px",
        },
      },
      sXDescription: {
        semibold: {
          fontSize: "15px",
          fontWeight: 600,
          lineHeight: "24px",
        },
        medium: {
          fontSize: "15px",
          fontWeight: 500,
          lineHeight: "24px",
        },
        regular: {
          fontSize: "15px",
          fontWeight: 400,
          lineHeight: "24px",
        },
      },
    },
    // Sizes of Elements

    // Sizes Components
    button: {
      primary: {
        fontSize: "14px",
        fontWeight: 600,
        lineHeight: "32px",
      },
      secondary: {
        fontSize: "15px",
        fontWeight: 500,
        lineHeight: "24px",
      },
    },

    textField: {
      text: {
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
      },
      label: {
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "24px",
      },
      placeholder: {
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "20px",
        fontFamily: "Poppins-Light",
      },
    },

    preview: {
      subtitle: {
        fontSize: "13px",
        fontWeight: 400,
        lineHeight: "16px",
      },
      question: {
        fontSize: "13px",
        fontWeight: 600,
        lineHeight: "19.5px",
      },
      link: {
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "32px",
      },
      tag: {
        fontSize: "10px",
        fontWeight: 400,
        lineHeight: "24px",
      },
      label: {
        fontSize: "13px",
        fontWeight: 400,
        lineHeight: "20px",
      },
    },
    tagLabel: { fontSize: "12px", fontWeight: 400, lineHeight: "24px" },

    tag: {
      text: {
        fontSize: "12px",
        fontWeight: 500,
        lineHeight: "18px",
      },
    },

    home: {
      desktop: {
        title: {
          fontSize: "48px",
          fontWeight: 700,
          lineHeight: "75.12px",
        },
        description: {
          fontSize: "17px",
          fontWeight: 400,
          lineHeight: "32px",
        },
      },
      mobile: {
        title: {
          fontSize: "32px",
          fontWeight: 700,
          lineHeight: "48px",
        },
        description: {
          fontSize: "13px",
          fontWeight: 400,
          lineHeight: "19.5px",
        },
      },
    },

    textAreaInput: {
      desktop: {
        placeholder: {
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "21px",
        },
      },
      mobile: {
        placeholder: {
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "21px",
        },
      },
    },

    radioComponent: {
      text: {
        fontSize: "13px",
        fontWeight: 400,
        lineHeight: "20px",
      },
    },

    card: {
      desktop: {
        title: {
          fontSize: "24px",
          fontWeight: 600,
          lineHeight: "40px",
        },
        text: {
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "22px",
        },
      },
      mobile: {
        title: {
          fontSize: "20px",
          fontWeight: 600,
          lineHeight: "32px",
        },
        text: {
          fontSize: "13px",
          fontWeight: 400,
          lineHeight: "19.5px",
        },
      },
    },
    // Sizes Components
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        html {
          scroll-behavior: smooth;
        }
        @font-face {
          font-family: 'Poppins';
          src: url('src/styles/fonts/Poppins-Regular.ttf') format('truetype');
          font-display: swap;
        }
        @font-face {
          font-family: 'Poppins-Bold';
          src: url('src/styles/fonts/Poppins-Bold.ttf') format('truetype');
          font-display: swap;
        }
        @font-face {
          font-family: 'Poppins-ExtraBold';
          src: url('src/styles/fonts/Poppins-ExtraBold.ttf') format('truetype');
          font-display: swap;
        }
        @font-face {
          font-family: 'Poppins-Light';
          src: url('src/styles/fonts/Poppins-Light.ttf') format('truetype');
          font-display: swap;
        }
        @font-face {
          font-family: 'Poppins-SemiBold';
          src: url('src/styles/fonts/Poppins-SemiBold.ttf') format('truetype');
          font-display: swap;
        }
        body {
          background-color: #1B1B1B
        }
      `,
    },
  },
});

export default theme;
