import { useState } from "react";
import { DateRange, RangeKeyDict } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

import theme from "../../definitions/theme";
import "./DateRange.css";
import { CalendarIcon, CalendarCross } from "../../assets";
import {
  Container,
  DateLabel,
  LabelContainer,
  DatePickerContainer,
} from "./styles";
import { useAppSelector } from "redux/store";
import dayjs from "dayjs";

interface DatePickerProps {
  isVisible: boolean;
  setIsVisible: any;
  startDate?: Date;
  endDate?: Date;
  setStartDate: React.Dispatch<React.SetStateAction<Date>>;
  setEndDate: React.Dispatch<React.SetStateAction<Date>>;
}

const RangeDatepicker = ({
  isVisible,
  setIsVisible,
  startDate,
  endDate,
  setEndDate,
  setStartDate,
}: DatePickerProps) => {
  const { dateFormat } = useAppSelector((state) => state.authSlice);
  const formatDate = (date) => dayjs(date).format(dateFormat);
  const handleDateChange = (ranges: RangeKeyDict) => {
    const { startDate, endDate } = ranges.range1;
    setStartDate(startDate);
    setEndDate(endDate);
  };
  const [hover, setHover] = useState(false);
  const [color, setColor] = useState(theme.customPalette.mediumLightGrey);

  return (
    <Container className="rangeDatePicker">
      <LabelContainer
        isVisible={isVisible}
        onClick={() => setIsVisible(true)}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <DateLabel>
          {startDate && endDate
            ? `${formatDate(startDate)} - ${formatDate(endDate)}`
            : `${dateFormat} - ${dateFormat}`}
        </DateLabel>
        {hover && startDate && endDate ? (
          <CalendarCross
            fill={color}
            onClick={() => {
              setStartDate(null);
              setEndDate(null);
            }}
            onMouseEnter={() => setColor(theme.customPalette.darkMediumGrey)}
            onMouseLeave={() => setColor(theme.customPalette.mediumLightGrey)}
          />
        ) : (
          <CalendarIcon />
        )}
      </LabelContainer>
      {isVisible && (
        <DatePickerContainer>
          <DateRange
            maxDate={new Date()}
            editableDateInputs={false}
            onChange={handleDateChange}
            ranges={
              startDate && endDate
                ? [{ startDate, endDate }]
                : [{ startDate: new Date(), endDate: new Date() }]
            }
            rangeColors={[theme.customPalette.hover]}
            color={theme.customPalette.secondary}
            scroll={{ enabled: true }}
            showDateDisplay={false}
          />
        </DatePickerContainer>
      )}
    </Container>
  );
};

export default RangeDatepicker;
